@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

#root {
	height: 100%;
	min-height: 100vh;
	overflow: auto;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
		'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100vh;

	--NavigationColor: #424242;
	--PrimaryColor: #222222;
	--SecondaryColor: #161616;
	--GuildItemsColor: #424242;
	--PrimaryTextColor: #f7f7f7;
	--ButtonColor: #f7f7f7;
	--ButtonHoverColor: #ff0460;
	background-color: var(--PrimaryColor);
}

body[data-theme='light'] {
	--NavigationColor: #424242;
	--PrimaryColor: #ffffff;
	--SecondaryColor: #161616;
	--GuildItemsColor: #424242;
	--PrimaryTextColor: #f7f7f7;
	--ButtonColor: #f7f7f7;
	--ButtonHoverColor: #ff0460;
}

.page {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
		monospace;
}

h1 {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 50px;
	display: block;
	color: var(--PrimaryTextColor);
}

h2 {
	font-family: 'Roboto', sans-serif;
	font-weight: normal;
	font-size: 30px;
	display: block;
	color: var(--PrimaryTextColor);
}

h3 {
	font-family: 'Roboto', sans-serif;
	font-weight: lighter;
	font-size: 20px;
	display: block;
	color: var(--PrimaryTextColor);
}

p {
	font-family: 'Roboto', sans-serif;
	font-weight: lighter;
	font-size: 10px;
	display: block;
	color: var(--PrimaryTextColor);
}

li {
	font-family: 'Roboto', sans-serif;
	font-weight: lighter;
	font-size: 10px;
	color: var(--PrimaryTextColor);
}

header {
	height: 90px;
	width: 100%;
	position: fixed;
	background-color: var(--PrimaryColor);
	display: flex;
	z-index: 10;
	flex-direction: row;
}

.button {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 20px;
	padding: 5px 15px;
	border: 2px solid var(--ButtonColor);
	color: var(--ButtonColor);
	border-radius: 25px;
	text-decoration: none;
	background: transparent;
	transition: all 0.3s ease-in-out;
}

.button:hover {
	border-color: var(--ButtonHoverColor);
	color: var(--ButtonHoverColor);
}

@keyframes rotate-icon {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.loading-icon {
	animation: rotate-icon 2s infinite linear;
	color: white;
}

.navigation-column {
	display: flex;
	width: 100%;
	height: 100%;
	align-items: center;
	flex-direction: row;
	background-color: #272727;
}

.navigation-column[data-pos='left'] {
	justify-content: flex-end;
	padding-right: 10%;
}

.navigation-column[data-pos='right'] {
	justify-content: flex-start;
	padding-left: 10%;
}

.navigation-column-right img {
	max-height: 50px;
	max-width: 50px;
}

.user-avatar {
	border-radius: 50%;
	max-width: 50px;
	max-height: 50px;
}

.user-dropdown {
	display: flex;
	position: relative;
	align-items: center;
}

@keyframes appear {
	from {
		margin-top: -5px;
		border: 0px solid var(--SecondaryColor);
		box-shadow: 0px 0px var(--SecondaryColor);
		//transform : translateX(-45%);
		opacity: 0;
	}

	to {
		margin-top: 10px;
		border: 1px solid var(--SecondaryColor);
		box-shadow: 2px 4px var(--SecondaryColor);
		//transform : translateX(-50%);
		opacity: 1;
	}
}

.user-dropdown-content {
	border-radius: 10%;
	min-width: 200px;
	display: flex;
	position: absolute;
	margin-top: 10px;
	top: 100%;
	left: 50%;
	transform: translateX(-80%);
	flex-direction: column;
	justify-content: flex-start;
	align-items: center;
	background-color: #222;
	border: 1px solid #161616;
	box-shadow: 2px 4px #161616;
	animation-name: appear;
	animation-duration: 0.5s;
	animation-iteration-count: 1;
}

.guild-items {
	display: inline-grid;
	grid-row-gap: 10px;
	grid-column-gap: 10px;
	grid-template-columns: repeat(3, 320px);
	grid-template-rows: inherit;
	padding-top: 90px;
	z-index: 1;
}

.guild-item {
	display: flex;
	flex-direction: column;
	height: 300px;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	border: 1px solid var(--PrimaryColor);
	transition: 0.8s ease;
}

.guild-item:hover {
	border: 1px solid var(--SecondaryColor);
	box-shadow: 2px 4px var(--SecondaryColor);
}

.guild-item .button {
	margin-top: 20px;
	min-width: 140px;
	border-width: 2px;
	text-align: center;
}

.guild-item-icon {
	position: relative;
	display: flex;
	max-height: 150px;
	max-width: 300px;
	height: auto width auto;
	align-items: center;
	justify-items: center;
	overflow: hidden;
	border-radius: 20px;
}

.guild-item-icon .icon-forground {
	width: 100px;
	height: 100px;
	position: absolute;
	object-fit: cover;
	border-radius: 50px;
	left: 50%;
	transform: translate(-50%, 0);
	background-color: var(--GuildItemsColor);
	border: 2px solid var(--GuildItemsColor);
}

.guild-item-icon .icon-background {
	width: 300px;
	height: 170px;
	object-fit: cover;
	filter: blur(8px);
}

.standard-page {
	padding-top: 90px;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	flex-direction: column;
	background-color: rgba(0, 0, 0, 0);
}

.standard-page h1 {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 100px;
	display: block;
	color: var(--PrimaryTextColor);
}

#Home {
	height: auto;
	padding-top: 0;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.auth-page {
	height: 100vh;
	background-color: rgba(0, 0, 0, 0);
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
}

.auth-page .loading-icon {
	animation: rotate-icon 2s infinite linear;
	color: white;
	width: 100px;
	height: 100px;
}

.auth-page h1 {
	font-size: 100px;
	display: block;
	color: var(--PrimaryTextColor);
}

.dropdown-button {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 20px;
	padding: 15px 0;
	color: var(--ButtonColor);
	text-decoration: none;
	border: 0px solid var(--PrimaryTextColor);
	background-color: rgba(255, 255, 255, 0);
	transition: all 0.3s ease-in-out;
}

.dropdown-button:hover {
	border-color: var(--ButtonHoverColor);
	color: var(--ButtonHoverColor);
}

.search-container {
	display: flex;
	flex-direction: row;
	position: fixed;
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 20px;
	padding: 5px 10px;
	background-color: var(--PrimaryColor);
	border: 2px solid var(--ButtonColor);
	color: var(--ButtonColor);
	z-index: 3;
	border-radius: 30px;
	align-items: center;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}

.search-container input {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 20px;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 25px;
	border: rgba(0, 0, 0, 0);
	color: var(--ButtonColor);
}

.search-container input:focus {
	outline: none;
}

.search-container:hover {
	border-color: var(--ButtonHoverColor);
	color: var(--ButtonHoverColor);
}

#invite img {
	max-height: 500px;
	max-width: 500px;
}

.clickable-icons-dark {
	color: white;
	transition: all 0.3s ease-in-out;
}

.clickable-icons-dark:hover {
	color: var(--ButtonHoverColor);
}

.clickable-icons-light {
	color: var(--PrimaryColor);
	transition: all 0.3s ease-in-out;
}

.clickable-icons-light:hover {
	color: var(--ButtonHoverColor);
}

.home-content-row {
	display: flex;
	flex-direction: row;
	align-items: center;
	height: 50vh;
	margin: 25vh 0;
	width: 90%;
	justify-content: center;
	background-color: var(--PrimaryColor);
	border-radius: 20px;
}

.home-content-row img {
	width: 400px;
	height: 400px;
}

.home-content-row-info {
	padding: 0 40px;
	display: flex;
	flex-direction: column;
	width: 700px;
}

.home-content-row-button {
	display: inline-block;
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 30px;
	padding: 6px 20px;
	margin: 0 10px;
	border: 2px solid var(--ButtonColor);
	color: var(--ButtonColor);
	border-radius: 10px;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
}

.home-content-row-button[pos='left'] {
	margin-left: 0;
}

.home-content-row-button[pos='right'] {
	margin-right: 0;
}

.home-content-row-button:hover {
	border-color: var(--ButtonHoverColor);
	color: var(--ButtonHoverColor);
}

.home-content-row-info h1 {
	font-size: 70px;
	margin: 2px 0;
	line-height: 40px;
}

.home-content-row-info h1::after {
	content: '';
	display: inline-block;
	height: 5px;
	width: 100%;
	background-color: white;
}

.home-content-row-info h3 {
	font-size: 30px;
}

#Dashboard {
	flex-direction: row;
	align-items: flex-start;
	height: auto;
	min-height: calc(100% - 90px);
}

.dashboard-content {
	display: grid;
	height: 100px;
	width: 100%;
	grid-template-columns: repeat(2, 300px);
	grid-template-rows: 150px;
	grid-gap: 20px;
	align-items: end;
	justify-content: space-evenly;
}

.dashboard-content-save {
	display: flex;
	position: fixed;
	bottom: 10%;
	right: 50%;
	transform: translateX(50%);
}

.dashboard-content-save[data-modified='false'] {
	visibility: collapse;
}

.dashboard-content-save button {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 20px;
	padding: 5px 15px;
	margin: 0 10px;
	border: 2px solid var(--ButtonColor);
	color: var(--ButtonColor);
	border-radius: 25px;
	text-decoration: none;
	background: transparent;
	transition: all 0.3s ease-in-out;
}

.dashboard-content-save button:hover {
	border-color: var(--ButtonHoverColor);
	color: var(--ButtonHoverColor);
}

.dashboard-sidebar {
	z-index: 10;
	display: flex;
	position: fixed;
	height: 100%;
	min-width: 250px;
	width: 250px;
	background-color: rgb(39, 39, 39);
	flex-direction: column;
	overflow: hidden;
	transition: all 0.3s ease-in-out;
}

.dashboard-menu-button {
	color: var(--ButtonColor);
	width: 50px;
	height: 50px;
	transition: all 0.3s ease-in-out;
}

.dashboard-menu-button:hover {
	color: var(--ButtonHoverColor);
}

.dashboard-sidebar-button {
	display: flex;
	width: inherit;
	height: 60px;
	border-left: 2px solid rgb(39, 39, 39);
	background-color: #272727;
	transition: border-color 0.3s ease-in-out;
	flex-direction: row;
	align-items: center;
}

.dashboard-sidebar-button-selected {
	display: flex;
	width: inherit;
	height: 60px;
	border-left: 2px solid var(--ButtonHoverColor);
	background-color: #272727;
	transition: border-color 0.3s ease-in-out;
	flex-direction: row;
	align-items: center;
}

.dashboard-sidebar-button-items {
	display: flex;
	width: fit-content;
	height: 60px;
	background-color: #272727;
	transition: all 0.3s ease-in-out;
	flex-direction: row;
	align-items: center;
	min-width: 300px;
	width: 300px;
}

.dashboard-sidebar-button-items h3 {
	font-family: 'Roboto', sans-serif;
	font-weight: normal;
	font-size: 20px;
	display: block;
	color: var(--PrimaryTextColor);
	transition: color 0.3s ease-in-out;
}

.dashboard-sidebar-icon {
	margin: 0 17.5px;
	display: flex;
	min-width: 25px;
	min-height: 25px;
	color: var(--ButtonColor);
	transition: all 0.3s ease-in-out;
}

.dashboard-sidebar-button:hover {
	border-color: var(--ButtonHoverColor);
}

.dashboard-sidebar-button:hover
	.dashboard-sidebar-button-items
	.dashboard-sidebar-icon {
	color: var(--ButtonHoverColor);
}

.dashboard-sidebar-button:hover .dashboard-sidebar-button-items h3 {
	color: var(--ButtonHoverColor);
}

.dashboard-sidebar-button-selected
	.dashboard-sidebar-button-items
	.dashboard-sidebar-icon {
	color: var(--ButtonHoverColor);
}

.dashboard-sidebar-button-selected .dashboard-sidebar-button-items h3 {
	color: var(--ButtonHoverColor);
}

.dashboard-setting {
	display: flex;
	flex-direction: column;
	position: relative;
	width: 300px;
	height: 100px;
	box-sizing: border-box;
}

.dashboard-setting h2 {
	font-size: 20px;
	text-align: center;
	-webkit-touch-callout: none;
	/* iOS Safari */
	-webkit-user-select: none;
	/* Safari */
	-khtml-user-select: none;
	/* Konqueror HTML */
	-moz-user-select: none;
	/* Old versions of Firefox */
	-ms-user-select: none;
	/* Internet Explorer/Edge */
	user-select: none;
}

.dashboard-setting-text {
	color: white;
	border: solid 2px white;
	border-radius: 20px;
	padding: 5px 10px;
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 15px;
	text-align: center;
	background: transparent;
	transition: all 0.3s ease-in-out;
}

.dashboard-setting-text:focus {
	outline: none;
}

.dashboard-setting-text:hover {
	border-color: var(--ButtonHoverColor);
}

.dashboard-setting-color {
	position: relative;
	display: flex;
	height: 28px;
	border: solid 2px white;
	border-radius: 20px;
	text-align: center;
	transition: all 0.3s ease-in-out;
	justify-content: center;
	align-items: center;
}

.dashboard-setting-color input {
	width: 100%;
	height: 100%;
	border-width: 0px;
	opacity: 0;
}

.dashboard-setting-color svg {
	position: absolute;
	color: white;
	font-size: 20px;
}

.dashboard-setting-dropdown {
	position: relative;
	height: 28px;
}

.dashboard-setting-dropdown-text {
	display: flex;
	border: solid 2px white;
	border-radius: 20px;
	height: 28px;
	transition: all 0.3s ease-in-out;
	justify-content: center;
	align-items: center;
}

.dashboard-setting-dropdown-clickable {
	position: absolute;
	height: 28px;
	width: 100%;
}

.dashboard-setting-dropdown h3 {
	margin: 0;
	font-size: 15px;
	font-weight: bold;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.dashboard-setting-dropdown svg {
	position: absolute;
	margin: 0;
	font-size: 20px;
	color: white;
	top: 50%;
	left: 90%;
	transform: translate(-90%, -50%);
	transition: all 0.3s ease-in-out;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.dashboard-setting-dropdown svg[data-open='true'] {
	transform: translate(-90%, -50%) rotate(180deg);
}

.dashboard-setting-dropdown-text:hover {
	border-color: var(--ButtonHoverColor);
}

/* width */
.dashboard-setting-dropdown-content::-webkit-scrollbar {
	width: 5px;
}

/* Track */
.dashboard-setting-dropdown-content::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
.dashboard-setting-dropdown-content::-webkit-scrollbar-thumb {
	background-color: white;
	border-radius: 10px;
}

.dashboard-setting-dropdown-content {
	position: absolute;
	display: flex;
	flex-direction: column;
	gap: 3px;
	width: 100%;
	min-height: inherit;
	max-height: 200px;
	overflow-y: overlay;
	margin-top: 5px;
	top: 100%;
	background-color: #222222;
	z-index: 3;
	border-radius: 5px;
	margin-bottom: 10px;
	transition: all 0.3s ease-in-out;
}

.dashboard-setting-dropdown-content-item {
	display: flex;
	position: relative;
	height: 30px;
	min-height: 30px;
	background-color: #494949;
	border-radius: 20px;
	transition: all 0.3s ease-in-out;
	justify-content: center;
	align-items: center;
}

.dashboard-setting-dropdown-content-item[data-state='selected'] {
	background-color: #34bef5;
}

.dashboard-setting-dropdown-content-item:hover {
	background-color: #34bef5;
}

.dashboard-setting-dropdown-content-item p {
	display: block;
	margin: 0;
	font-size: 15px;
	color: white;
	font-weight: 800;
	text-overflow: ellipsis;
	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.dashboard-setting-dropdown-content-item svg {
	position: absolute;
	display: block;
	margin: 0;
	top: 50%;
	left: 90%;
	transform: translate(-90%, -50%);
	color: white;

	-webkit-touch-callout: none; /* iOS Safari */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Old versions of Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
	user-select: none;
}

.dashboard-input-row {
	display: flex;
	min-width: 400px;
	height: inherit;
	background-color: var(--GuildItemsColor);
	border-radius: 20px;
}

.dashboard-input-row h3 {
	margin: 0 0;
}

.dashboard-text-input {
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 20px;
	background-color: rgba(0, 0, 0, 0);
	border-radius: 20px;
	border: rgba(0, 0, 0, 0);
	color: rgba(0, 0, 0, 0);
	outline: none;
	width: inherit;
}

.dashboard-text-input:focus {
	outline: none;
}

.level-card-customization {
	--main-color: #87ceeb;
	--progress-percent: 50%;
	--opacity: 0.8;
	--scale: 0.5;
	display: flex;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 20;
	background-color: rgba(0, 0, 0, 0.9);
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.level-card-customization-button {
	display: inline-block;
	font-family: 'Roboto', sans-serif;
	font-weight: bold;
	font-size: 20px;
	padding: 6px 20px;
	margin-top: 20px;
	border: 2px solid var(--ButtonColor);
	color: var(--ButtonColor);
	border-radius: 10px;
	text-decoration: none;
	background: transparent;
	transition: all 0.3s ease-in-out;
}

.level-card-customization-button:hover {
	border-color: var(--ButtonHoverColor);
	color: var(--ButtonHoverColor);
}

.level-card-customization-content {
	display: flex;
	width: 650px;
	height: 500px;
	justify-content: center;
	background-color: #222222;
	flex-direction: column;
	align-items: center;
	border-radius: 20px;
}

.level-card-customization-content-opacity-slider {
	display: flex;
	position: relative;
	margin-top: 20px;
	width: 500px;
	height: 30px;
	align-items: center;
	border-radius: 10px;
}

.level-card-customization-content-opacity-slider-track {
	display: block;
	position: absolute;
	width: 100%;
	height: 10px;
	background-color: var(--main-color);
	border-radius: 5px;
	border: solid 2px black;
}

.level-card-customization-content-opacity-slider-thumb {
	display: block;
	position: absolute;
	left: calc(var(--opacity) * 100%);
	transform: translateX(calc(-25% * (1 + 2 * var(--opacity))));
	padding: 5px;
	width: 20px;
	height: 20px;
	color: white;
	background-color: var(--main-color);
	border-radius: 20px;
	border: solid 2px black;
}

.level-card-customization-content-opacity-slider input[type='range'] {
	margin: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	position: absolute;
}

.level-card-customization-backround-crop {
	position: relative;
	display: flex;
	width: 650px;
	height: 500px;
	justify-content: center;
	background-color: #222222;
	flex-direction: column;
	align-items: center;
	border-radius: 20px;
}

.level-card-customization-backround-crop-image {
	display: flex;
	max-width: 600px;
	max-height: 400px;
	justify-content: center;
}

.ReactCrop__image {
	object-fit: cover;
	max-width: 100%;
	max-height: 400px;
}

.level-card-editing-content {
	display: flex;
	width: 500px;
	height: 75px;
	margin-top: 20px;
	flex-direction: row;
	justify-content: space-between;
}

.level-card-editing-background-upload-loading {
	position: absolute;
	height: 100%;
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 20px;
	background-color: rgba(0, 0, 0, 0.8);
	z-index: 20;
}

.level-card-editing-background-upload-loading .loading-icon {
	color: white;
	font-size: 100px;
}

.level-card-editing-item {
	position: relative;
	height: 75px;
	width: 200px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-radius: 10px;
	background-color: var(--main-color);
	border: solid 2px black;
	transition: border 0.3s ease-in-out;
}

.level-card-editing-item-background {
	position: absolute;
	height: 100%;
	width: 100%;
	border-radius: 10px;
	opacity: 0;
	background-color: black;
	transition: opacity 0.3s ease-in-out;
}

.level-card-editing-item input[type='color'] {
	opacity: 0;
	width: 100%;
	height: 100%;
}

.level-card-editing-item input[type='file'] {
	opacity: 0;
	width: 100%;
	height: 100%;
}

.level-card-editing-item-icon {
	position: absolute;
	color: white;
	width: 30px;
	height: 30px;
	transition: all 0.15s ease-in-out;
}

.level-card-editing-item:hover {
	border-color: var(--main-color);
}

.level-card-editing-item:hover .level-card-editing-item-background {
	opacity: 0.7;
}

// level card stuff
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500&display=swap');

.level-card {
	font-family: 'Poppins', Arial, Helvetica, sans-serif;
	background: rgb(22, 22, 22);
	color: #222;
	width: calc(1000px * var(--scale));
	height: calc(300px * var(--scale));
	overflow: hidden;
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: calc(8px * var(--scale));
}

.level-card h1 {
	font-family: 'Poppins', sans-serif;
	font-weight: 500;
	font-size: calc(40px * var(--scale));
	display: block;
	color: white;
	margin: 0;
}

.level-card h2 {
	font-family: 'Poppins', sans-serif;
	font-weight: 300;
	font-size: calc(30px * var(--scale));
	display: block;
	color: white;
	margin: 0;
}

.level-card h3 {
	font-family: 'Poppins', sans-serif;
	font-weight: 200;
	font-size: calc(20px * var(--scale));
	display: block;
	color: white;
	margin: 0;
}

.level-card-background {
	width: calc(1000px * var(--scale));
	height: calc(300px * var(--scale));
	object-fit: cover;
	position: absolute;
	border-radius: calc(8px * var(--scale));
}

.user-level-profile {
	position: relative;
	min-width: calc(184px * var(--scale));
	width: calc(184px * var(--scale));
	height: calc(184px * var(--scale));
	display: block;
}

.user-level-info {
	width: inherit;
	height: inherit;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
}

.user-level-info-row {
	width: 100%;
	height: calc(50px * var(--scale));
	display: flex;
	flex-direction: row;
	position: relative;
	justify-content: space-between;
	box-sizing: border-box;
	padding: 0 calc(20px * var(--scale));
}

.user-level-info-row[pos='top'] {
	height: calc(70px * var(--scale));
}

.user-level-info-row[pos='middle'] {
	height: calc(60px * var(--scale));
	align-items: center;
}

.user-level-info-bar {
	display: block;
	background-color: grey;
	width: 100%;
	height: calc(30px * var(--scale));
	box-sizing: border-box;
	border-radius: calc(20px * var(--scale));
}

.user-level-info-progress {
	display: block;
	background-color: var(--main-color);
	width: var(--progress-percent);
	height: calc(30px * var(--scale));
	box-sizing: border-box;
	border-radius: calc(20px * var(--scale));
}

.user-level-info-row[pos='top']::after {
	content: '';
	display: block;
	height: 1px;
	width: calc(686px * var(--scale));
	background-color: var(--main-color);
	position: absolute;
	top: 100%;
}

.user-level-profile img {
	position: absolute;
	width: calc(180px * var(--scale));
	height: calc(180px * var(--scale));
	border-radius: calc(110px * var(--scale));
	border: 2px groove black;
	display: inline-block;
}

.online-status {
	position: absolute;
	width: calc(30px * var(--scale));
	height: calc(30px * var(--scale));
	border-radius: calc(100px * var(--scale));
	background-color: green;
	border: 2px solid black;
	display: inline-block;
	transform: translateY(-50%) translateX(-50%);
	left: 85%;
	top: 85%;
}

.level-card-content {
	position: relative;
	width: calc(950px * var(--scale));
	height: calc(220px * var(--scale));
	display: flex;
	flex-direction: row;
	overflow: hidden;
	background-color: rgba(34, 34, 34, var(--opacity));
	justify-content: flex-start;
	align-items: center;
	box-sizing: border-box;
	padding: calc(20px * var(--scale));
	border-radius: calc(8px * var(--scale));
}

.command-list {
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 100px;
	gap: 20px;
	padding-bottom: 20px;
}

.command-item {
	width: 95%;
	padding: 20px;
	display: flex;
	flex-direction: column;
	background-color: var(--PrimaryColor);
	border-radius: 20px;
	align-items: center;
}

.command-item h2 {
	margin: 10px 0;
	font-size: 30px;
	width: 60%;
	text-align: center;
	padding-bottom: 20px;
	border-bottom: 1px solid white;
}

.command-item p {
	font-size: 29px;
	margin: 5px 0;
	text-align: center;
}

#tos-privacy {
	margin: 0 10%;
	height: auto;
}

#tos-privacy h1 {
	font-size: 40px;
	width: 100%;
	padding-bottom: 20px;
	border-bottom: solid 2px white;
	text-align: center;
}

#tos-privacy h2 {
	font-size: 20px;
	width: 100%;
}

#tos-privacy p {
	font-size: 20px;
	width: 100%;
}

#tos-privacy a {
	color: white;
	text-decoration: underline;
}

#tos-privacy li {
	font-size: 20px;
	width: 100%;
	padding: 10px 0;
}

@media only screen and (max-width: 1200px) {
	h1 {
		font-size: 30px;
	}

	h2 {
		font-size: 18px;
	}

	h3 {
		font-size: 12px;
	}

	.dashboard-sidebar {
		width: 0px;
		min-width: 0px;
		top: 0;
		left: 0;
	}

	.dashboard-content-save button {
		font-family: 'Roboto', sans-serif;
		font-weight: bold;
		font-size: 15px;
		padding: 5px 15px;
		margin: 0 10px;
		border: 2px solid var(--ButtonColor);
		color: var(--ButtonColor);
		border-radius: 25px;
		text-decoration: none;
		background: transparent;
		transition: all 0.3s ease-in-out;
	}

	.guild-items {
		grid-template-columns: repeat(2, 320px);
	}
}

@media only screen and (max-width: 1000px) {
	.home-content-row {
		flex-direction: column;
	}

	.home-content-row img {
		width: 300px;
		height: 300px;
		margin: 40px 0;
	}

	.home-content-row-info {
		padding: 0 40px;
		display: flex;
		flex-direction: column;
		width: 700px;
	}

	.home-content-row-info h1 {
		font-size: 70px;
		margin: 2px 0;
		text-align: center;
	}

	.home-content-row-info h1::after {
		content: '';
		display: inline-block;
		height: 5px;
		width: 100%;
		background-color: white;
	}

	.home-content-row-info div {
		display: flex;
		justify-content: center;
	}

	.home-content-row-button {
		font-size: 30px;
		text-align: center;
	}

	.home-content-row-info h3 {
		text-align: center;
	}
}

@media only screen and (max-width: 780px) {
	.guild-items {
		grid-template-columns: repeat(1, 320px);
	}

	.home-content-row {
		height: fit-content;
		margin: 0 0;
		margin-top: 90px;
	}

	.home-content-row-info {
		width: 400px;
	}

	.home-content-row-info div {
		flex-direction: column;
		align-items: center;
	}

	.home-content-row-button {
		margin: 10px 0;
		font-size: 20px;
	}

	.home-content-row-info h3 {
		font-size: 30px;
	}

	.dashboard-content {
		grid-template-columns: repeat(1, 300px);
	}

	.level-card-customization {
		--scale: 0.3;
	}

	.level-card-customization-backround-crop {
		width: 100%;
		height: 100%;
		border-radius: 0px;
	}

	.level-card-customization-backround-crop-image {
		max-width: 350px;
		max-height: 400px;
	}

	.level-card-customization-content {
		width: 100%;
		height: 100%;
		border-radius: 0px;
	}

	.level-card-editing-content {
		width: 300px;
		height: 50px;
	}

	.level-card-editing-item {
		height: 50px;
		width: 100px;
		border-radius: 5px;
	}

	.level-card-editing-item-background {
		border-radius: 5px;
	}

	.level-card-customization-content-opacity-slider {
		width: 300px;
	}

	.command-item h2 {
		font-size: 25px;
	}

	.command-item p {
		font-size: 18px;
	}
}

@media only screen and (max-width: 480px) {
	.home-content-row img {
		width: 200px;
		height: 200px;
		margin: 40px 0;
	}

	.home-content-row-info {
		width: 280px;
		margin-bottom: 20px;
		padding: 0;
	}

	.home-content-row-info h1 {
		font-size: 50px;
		margin: 2px 0;
		text-align: center;
	}

	.home-content-row-info h3 {
		font-size: 20px;
	}

	.home-content-row-button {
		font-size: 20px;
	}

	.command-item {
		padding: 0;
	}
}
